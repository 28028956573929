button {
  cursor: pointer;
}

input {
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

/*
semi-bold: 600
medium: 500
regular: 400
*/

/* .typical-font-style {
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
} */

.pc-head {
  font-weight: bold;
  line-height: 110%;
}

.pc-subtitle {
  font-weight: 500;
  line-height: 110%;
}

.pc-body {
  font-weight: 400;
  line-height: 24px;
}

.pc-caption {
  font-weight: 500;
  line-height: 140%;
}

.pc-button {
  font-weight: 500;
  line-height: 100%;
}

.fs-54 {
  font-size: 54px;
}

.fs-42 {
  font-size: 42px;
}

.fs-32 {
  font-size: 32px;
}

.fs-24 {
  font-size: 24px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}


.h1-14-b {
  font-size: 14px;
  font-weight: bold;
  line-height: auto;
}

.h2-18-sb {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.h3-20-b {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.h4-28-b {
  font-size: 28px;
  font-weight: bold;
  line-height: 28px;
}

.h5-24-b {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
}

.b0-10-m {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
}

.b1-12-m {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.b2-12-sb {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.b3-14-m {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.b4-14-sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.b5-16-sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.b6-16-m {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.b7-16-sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.b1-12-m-14 {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.b7-16-sb-18 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.b6-16-m-18 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

@media (min-width: 768px) {
  .b1-12-m-14 {
    font-size: 14px;
  }

  .b7-16-sb-18 {
    font-size: 18px;
  }

  .b6-16-m-18{
    font-size: 18px;
  }
}