@import '../../styles/colors.css';

.Card--container {
  position: relative;
  height: 268px;
  width: 316px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.05);
}

.Card--container-header {
  height: 28px;
  width: 100%;
  background-color: #727272;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
}

/* .Card--header-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  color: white;
  margin-left: 12px;
} */


.Card--picture-container {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #DCE0E5;
  margin-top: 20px;
  margin-bottom: 16px;
}

.Card--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 112px;
  width: auto;
  text-align: center;
}

.Card--content-name {
  font-size: 20px;
  line-height: 120%;
  word-spacing: -0.5%;
  font-weight: 600;
  margin-bottom: 8px;
}

.Card--content-location {
  display: flex;
  align-items: center;
  width: auto; height: auto;
  margin-bottom: 20px;
  color: #6B6B6B;
}

.Card--content-location-img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.Card--content-button {
  width: 258px;
  height: 30px;
  border: 1px solid #E8E8E8;
  border-radius: 20px;
  background-color: transparent;
  color: #6B6B6B;
}

@media (min-width: 0px) {
  .Card--container {
    width: 170px; height: 142px;
    border: 0.56px solid #E8E8E8;
    background-color: white;
    box-shadow: 0px 0px 16.68px 0px rgba(0,0,0,0.05);
    border-radius: 4.45px;
    /* gap: 23px; */
    justify-content: space-evenly;
    cursor: pointer;
  }

  .Card--header-container {
    display: flex;
    gap: 12px;
  }

  .Card--header-text {
    width: auto; height: auto;
    max-width: 70px;
  }

  .Card--header-img-container {
    height: 64px; width: 64px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .Card--header-img {
    object-fit: cover;
    width: 100%; height: 100%;
    display: block;
    margin: auto;
  }

  .Card--content-container {
    width: 150px; height: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .Card--content-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: var(--black-text);
    margin: 0;
  }

  .Card--content-intro {
    white-space: nowrap; /* 텍스트를 한 줄로 표시 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */
  }
}

@media (min-width: 768px) {
  .Card--container {
    /* max-width: 319px;
    max-height: 262px;
    width: 50vw; height: calc(30vw/1.217); */
    /* max-width: 319px; */
    /* width: 30vw; */
    width: 319px;
    height: 262px;
    gap: 30px;
    justify-content: center;
  }

  .Card--header-img-container {
    height: 142px; width: 142px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .Card--header-container {
    display: flex;
    gap: 19px;
  }

  .Card--header-text {
    width: 119px; height: auto;
    max-width: 119px;
  }

  .Card--content-container {
    width: 280px;
    gap: 11px;
  }

  .Card--content-name {
    font-size: 20px;
  }

  .Card--content-intro {
    white-space: nowrap; /* 텍스트를 한 줄로 표시 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */
    font-size: 16px;
  }

  .pc-font-14{
    font-size: 14px;
  }
}