@import '../../styles/colors.css';

.SearchBar-container {
  width: 100%;
  height: 52px;
  border-radius: 30px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
}

.SearchBar--input {
  margin-left: 10px;
  height: 26.83px;
  width: 100%;
  overflow-x: scroll;
  background-color: transparent;
  outline: none;
  border: none;
}

.SearchBar--icon {
  width: 24px;
  height: 24px;
  background-image: url("./../../assets/search-icon.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  margin-left: 20px;
}

@media (min-width: 0px) {
  .SearchBar-container {
    width: 100%; height: 45px;
    background-color: var(--bg-grey);
    border: 1px solid var(--grey1);
  }

  .SearchBar--icon {
    width: 16px; height: 16px;
    margin-left: 12px;
  }

  .SearchBar--input {
    width: 100%; height: 28px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 8px;
    margin-right: 10px;
  }

  .SearchBar--input::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--grey3);
  }

  .SearchBar--input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    cursor: pointer;
    background-color: var(--grey3);
    border: none;
    border-radius: 50%;
    position: relative;
    background-image: url('./../../assets/clear-icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

@media (min-width: 768px) {
  .SearchBar-container {
    width: 100%; height: 48px;
    background-color: var(--bg-grey);
    border: 1px solid var(--grey1);
  }
}