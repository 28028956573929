@import '../../../styles/colors.css';

@media (min-width: 0px) {
  .SignIn--container {
    width: 100vw; height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .SignIn--logo-gif {
    width: 100%; height: auto;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .SignIn--button-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    margin-bottom: 99px;
  }

  .SignIn--google-button {
    width: 342px; height: 52px;
    border: 1px solid black;
    background-color: white;
    color: black;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 4px; */

    &.black {
      background-color: black;
      color: white;
    }
  }

  .SignIn--google-icon {
    width: 30px; height: 30px;
    background-image: url('../../../assets/GVZIP_icons/Google_icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .SignIn--close-button-container {
    display: none;
  }

  /* .SignIn--close-button-container {
    width: 100%;
    height: auto;
  } */

  .SignIn--close-button {
    display: none;
  }

  /* .SignIn--close-button {
    width: 36px; height: 36px;
    border: none;
    background-color: transparent;
    background-image: url('../../../assets/login-exit.png');
    margin-top: 24px;
    margin-left: 24px;
  } */

  .SignIn--logo-gif {
    width: 465px;
    margin-top: 100px;
  }

  /* .SignIn--logo-gif {
    width: 465px;
    margin-top: 70px;
  } */

  .SignIn--button-container {
    margin-bottom: 364px;
  }

  .SignIn--google-button {
    width: 400px;
  }
}