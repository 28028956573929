@import './../../styles/colors.css';

@media (min-width: 0px) {
  .bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 10;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    touch-action: none; /* 터치 이벤트를 막음 */
    pointer-events: auto; /* 포인터 이벤트를 활성화 */
    will-change: transform, height; /* 애니메이션 성능 향상 */
  }
  
  .bottom-sheet-handle {
    width: 60px;
    height: 6px;
    background-color: #D9D9D9;
    border-radius: 4px;
    margin: 12px auto 32px auto;
    cursor: grab;
  }
  
  .bottom-sheet-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(43, 55, 68, 0.7);
    z-index: 5;
    touch-action: none; /* 터치 이벤트를 막음 */
    pointer-events: auto; /* 포인터 이벤트를 활성화 */
  }
  
  .bottom-sheet-content {
    position: relative;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .EditBottomSheet--container {
    width: 100vw; height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 100;
    background-color: white;
    touch-action: none; /* 터치 이벤트를 막음 */
    pointer-events: auto; /* 포인터 이벤트를 활성화 */
  }

  .EditBottomSheet--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .EditBottomSheet--modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 55, 68, 0.7);
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    touch-action: none; /* 터치 이벤트를 막음 */
    pointer-events: auto; /* 포인터 이벤트를 활성화 */
  }
}

@media (min-width: 768px) {
  .bottom-sheet {
    position: absolute;
    top: 71px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  /* .bottom-sheet-handle {
    display: none;
  }

  .bottom-sheet-overlay {
    display: none;
  } */

  .EditBottomSheet--modal-background {
    background-color: transparent;
    display: none;
  }

  .EditBottomSheet--container{
    position:absolute;
    top: 20px;
    right: 0px;
    width: 160px;
    height: 102px;
    border: 0.5px solid #D3DEE6;
    border-radius: 12px;
    box-shadow: 3px 8px 15px 0px rgba(0, 0, 0, 0.07);
  }

  .delete-border-on-PC{
    width: 160px;
    margin-top: -15px;
    padding-top: 15px;
    border-top: 0.5px solid #D3DEE6;
  }
}