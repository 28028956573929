@import '../../../../styles/colors.css';


@media (min-width: 0px) {
  .MembershipAuth--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .MembershipAuth--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; height: auto;
    max-width: 342px;
  }
  
  .MembershipAuth--content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .MembershipAuth--header {
    width: 342px; height: 145px;
    border: none;
    background-color: var(--bg-grey);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MembershipAuth--header-content {
    width: 278px; height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--black-text);
    gap: 24px;
  }
  
  .MembershipAuth--link-share {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
    cursor: pointer;
    text-decoration: underline;
  }

  .div_b3-14-m{
    padding: 20px 20px 20px 20px;
    background-color: #fafafa; /* Background color */
    border-radius: 5px; /* Rounded corners */
  }
}

@media (min-width: 768px) {
  .div_b3-14-m{
    width: 360px;
  }

  .MembershipAuth--link-share {
    font-size: 14px;
  }
  
  .MembershipAuth--header {
    width: 400px; height: 166px;
    margin-top: -80px;
  }

  .MembershipAuth--header-content {
    width: 341px; height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--black-text);
    gap: 24px;
  }

  .MembershipAuth--container {
    max-width: 400px;
  }

  .MembershipAuth--content-container {
    gap: 12px;
    margin-bottom: 200px;
  }

}
