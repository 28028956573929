@import '../../styles/colors.css';

.HistoryDetail--container {
  width: 100%; height: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--black-text);
  position: relative;
}

.HistoryDetail--title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HistoryDetail--button {
  width: 20px; height: 20px;
  border: none;
  background-color: transparent;
  background-image: url('../../assets/details-menu.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.HistoryDetail--title-font {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

/* .HistoryDetail--edit {
  float: right;
} */