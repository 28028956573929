@import '../../../styles/colors.css';

@media (min-width: 0px) {
  .SignUp--container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .SignUp--header {
    width: 100%; height: auto;
    border: none;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  
  .SignUp--title {
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    text-align: center;
    color: #2f2f2f;
    margin-top: 66px;
    margin-bottom: 32px;
  }

  .SignUp--button-container {
    width: 268px; height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 40px;
  }

  .SignUp--button {
    width: 128px; height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 6px;
    background-color: white;
    color: var(--black-text);
  }

  .SignUp--content-background {
    width: auto; height: 310px;
    min-width: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/GVZIP_icons/Red\ folder_-02.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .SignUp--container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .SignUp--header {
    display: none;
  }
  
  .SignUp--title {
    font-size: 20px;
    position: relative;
    top: 278px;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .SignUp--button-container {
    width: 332px;
    margin-top: 160px;
  }

  .SignUp--button {
    width: 160px; height: 100px;
  }

  .SignUp--content-background {
    width: 586.5px; height: 546px;
    margin-top: 68px;
    background-size: 100%;
  }
}