@import '../../styles/colors.css';

@media (min-width: 0px) {
  .Footer--container {
    width: 100%; height: auto;
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    color: white;
  }

  .Footer--content-container {
    display: flex;
    flex-direction: column;
    width: 100%; height: auto;
    margin: 24px 24px 40px 24px;
    gap: 13px;
  }

  .Footer--content-header {
    display: flex;
    gap: 42px;
    width: 100%; height: auto;
    flex-direction: column;
  }

  .Footer--rights {
    color: var(--grey1);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0%;
    font-weight: 500;
  }

  .Footer--agreement {
    font-size: 12px;
    line-height: 140%;
    font-weight: 600;
    width: auto; height: auto;
    display: flex;
    gap: 24px;
  }

  .Footer--sns-container {
    display: flex;
    gap: 24px;
  }

  .Footer--sns-buttons {
    height: 32px; width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    overflow: hidden;
    padding: 0;
  }

  .Footer--sns-buttons-img {
    width: 100%; height: 100%;
    object-fit: contain;
  }
}

@media (min-width: 768px) {
  .Footer--container {
    width: 100%; height: 320px;
    background-color: var(--black);
    display: flex;
    flex-direction: row;
  }

  .Footer--content-container {
    display: flex;
    gap: 4px;
    flex-direction: column;
    width: 100%; height: auto;
    margin: 48px;
    justify-content: space-between;
  }

  .Footer--content-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .Footer--agreement {
    font-size: 18px;
    width: 240px; height: auto;
    display: flex;
    justify-content: space-between;
  }

  .Footer--sns-container {
    gap: 48px;
  }

  .Footer--sns-buttons {
    height: 64px; width: 64px;
  }
}