@import './../../../../styles/colors.css';

@media (min-width: 0px) {

  .Privacy--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .Privacy--container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    width: auto; height: auto;
    max-width: 342px;
    margin-top: 30px;
  }

  .Privacy--header{
    display: none;
  }
  
  .Privacy--title-mobile {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 12px;
  }
  
  .Privacy--content {
    height: auto; width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .TermsOfUse--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .TermsOfUse--container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    width: auto; height: auto;
    max-width: 342px;
  }

  .TermsOfUse--content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: auto; height: auto;
  }

  .TermsOfUse--header{
    /* display: none; */
    margin-top: 30px;
  }

  .TermsOfUse-close-button-disable {
    display: none;
  }

  .TermsOfUse-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0%;
  }
}

@media (min-width: 768px) {
  .Privacy--wrapper{
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #68717a;
    display: flex;
  }
  
  .Privacy--container{
    display: block;
    width: 400px;
    height: 630px;
    max-width: 400px;
    border-radius: 30px;
    background-color: white;
    padding: 40px 98px 40px 99px;
    /* margin: 600px 0px 254px 0px; */
    /* position: fixed;
    top: 120; */
    /* margin-bottom: 254px; */
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .Privacy--title-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .Privacy--header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .Privacy--title-mobile {
    margin-bottom: 12px;
  }

  .Privacy-title {
    display: block;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
  }
  
  .Privacy-close-button {
    /* margin-left: auto; */
    margin-right: -58px; /* 32px + 26px */
    background-color: transparent;
    border: none;
    width: 32px; height: 32px;
    background-image: url('../../../../assets/modal-close.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .TermsOfUse--wrapper{
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #68717a;
    display: flex;
    padding-bottom: 254px;
  }
  
  .TermsOfUse--container{
    display: block;
    width: 400px;
    height: 630px;
    max-width: 400px;
    border-radius: 30px;
    background-color: white;
    padding: 40px 98px 40px 99px;
    /* margin: 300px 0px 254px 0px; */
    /* position: fixed;
    top: 120; */
    /* margin-bottom: 254px; */
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .TermsOfUse--container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .TermsOfUse--header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .TermsOfUse-title {
    display: block;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
  }
  
  .TermsOfUse-close-button {
    /* margin-left: auto; */
    margin-right: -58px; /* 32px + 26px */
    background-color: transparent;
    border: none;
    width: 32px; height: 32px;
    background-image: url('../../../../assets/modal-close.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }
}