@import './../../styles/colors.css';

.NavBar--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 70px;
  width: 100vw;
  border-bottom: 1px solid #d6d6d6;
  flex-direction: row;
}

/* .NavBar--logo-wrap {
  width: 106px; height: 43px;
  margin: 16px 16px 51px 16px;
} */

.NavBar--logo-wrap:hover {
  cursor: pointer;
}

.NavBar--logo {
  height: 29.28px;
  width: 148px;
}

.NavBar--buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 308px;
  margin-right: 24px;
}

.Navbar--buttons-box {
  width: 76px; height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid black;
  }
}

@media (min-width: 0px) {
  .NavBar--container {
    height: auto; width: 100%;
    flex-direction: column;
    align-items: initial;
    /* padding: 12px 0 0 12px; */
  }

  .NavBar--logo {
    height: 100%; width: 100%;
  }

  .NavBar--logo-wrap {
    width: 106px;
    /* height: 43px; */
    margin: 16px 16px 17px 16px;
  }

  .NavBar--buttons-container {
    height: 34px; width: 100%;
  }

  .Navbar--buttons-box {
    width: calc(100% / 3);
  }
}

@media (min-width: 768px) {
  .NavBar--container {
    flex-direction: row;
  }

  .NavBar--logo-wrap {
    width: 148px; height: 29.28px;
    margin: 20.36px 16px 20.36px 44px;
  }

  .NavBar--buttons-container {
    height: 70px;
    width: 308px;
  }
  
  .Navbar--buttons-box {
    width: 120px; height: 100%;
  }
}