@import './../../styles/colors.css';

@font-face {
  font-family: 'Recoleta';
  src: url('./../../assets/Recoleta-RegularDEMO.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


/* About */
.Landing--about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 312px;
  /* width: 100vw; */
  background-color: #000;
  opacity: 60%;
  color: white;
  /* padding: 0 120px; */
  text-align: center;
}

/* .Landing--about-content {
  min-width: 328px;
} */

/* Values */
.Landing--values {
  height: 840px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  /* margin: 180px 0 180px 0; */
}

.Landing--values-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38.1%;
  width: 83.3%;
  margin: 0 120px 0 120px;
  /* gap: 120px; */
}

.Landing--values-picture {
  height: 320px;
  width: 558px;
  border: 1.5px solid var(--grey1);
  border-radius: 12px;
}

.Landing--values-content {
  width: 530px; height: auto;
  display: flex;
  flex-direction: column;
}

.title-margin {
  margin-bottom: 28px;
}

.subtitle-margin {
  margin-bottom: 8px;
}

/* Connect */
.Landing--connect-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Landing--connect-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 140%;
  text-align: center;
  margin-bottom: 33px;
  min-width: 354px;
}

/* Contact Us */
.Landing--ddg {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Landing--ddg-title {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  height: 75px;
  width: 1227px;
  text-align: left;
}


.Landing--contact {
  margin: 80px 0 120px 0;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .Landing--poster {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 39px 0;
    background-image: url('./../../assets/landing-poster.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 655px;
    /* height: 100vh; */
    min-width: 390px;
  }

  .Landing--about {
    height: 220px;
    background-color: var(--grey4);
    opacity: 1;
    color: var(--secondary-yellow);
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Landing--about-content {
    width: 223px;
    height: 56px;
  }

  .Landing--values {
    width: auto;
    height: auto;
    align-items: center;
    margin-top: 100px;
    gap: 80px;
  }

  .Landing--values-container {
    height: auto;
    width: 326px;
    flex-direction: column;
    /* margin: 0; */
    gap: 24px;
  }

  .Landing--values-picture {
    height: 187px;
    /* width: 326px; */
    width: 100%;
    /* margin-bottom: 20px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.value-1 {
      background-image: url('../../assets/value-1.jpeg');
    }

    &.value-2 {
      background-image: url('../../assets/value-2.jpeg');
    }
  }

  .Landing--values-content {
    width: 100%;
    height: auto;
  }

  .m1-16 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  .b3-20 {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
  }

  .title-margin {
    margin-bottom: 12px;
  }

  .subtitle-margin {
    margin-bottom: 8px;
  }

  .Landing--connect-container {
    height: 182px;
    justify-content: initial;
    padding: 144px 0 120px 0;
  }

  .Landing--connect-title {
    height: 90px;
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
  }

  .Landing--ddg {
    align-items: center;
  }

  .Landing--ddg-title {
    width: 326px;
    height: auto;
    flex-direction: column;
    margin-bottom: 24px;
    color: var(--black-text);
  }

  .Landing--ddg-intro-section {
    width: auto; height: auto;
    max-width: 343px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
  }

  .Landing--ddg-picture-container {
    width: 166px; height: 120px;
    background-color: var(--grey4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3.43px;
    padding: 0.86px 0;
    position: relative;
  }
  
  .Landing--ddg-picture-wrapper {
    width: 133.71px; height: 100%;
    background-color: white;
    position: relative;
    overflow: hidden;
  }

  .Landing--ddg-picture {
    width: 113%; height: auto;
    position: absolute;
    bottom: 0px;
    left: -2px;
  }

  .Landing--ddg-name {
    width: auto; height: 16px;
    padding: 0 4px;
    background-color: var(--grey4);
    position: absolute;
    bottom: 0; left: 0;
    border-bottom-left-radius: 3.43px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .Landing--ddg-name-font {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #95EF5D;
  }

  .Landing--ddg-role {
    font-family: 'Recoleta', serif !important;
    font-size: 10px;
    line-height: 16px;
    color: var(--black-text);
    position: absolute;
    top: 4px; left: 4px;
  }
}

@media (min-width: 768px) {
  .Landing--poster {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    background-image: url('./../../assets/landing_poster_PC.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    width: 100vw;
    height: 100vh;
    min-width: 390px;
  }

  .Landing--about {
    height: 275px;
    background-color: var(--grey4);
    opacity: 1;
    color: var(--secondary-yellow);
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Landing--about-content {
    width: 800px;
    font-size: 32px;
  }

  .Landing--values-container {
    /* height: auto; */
    max-width: 1200px;
    width: 83%;
    flex-direction: row;
    margin: auto;
    gap: auto;
  }

  .Landing--values {
    width: 100%;
    height: auto;
    align-items: center;
    margin-top: 160px;
    gap: 160px;
  }

  .Landing--values-picture {
    height: 320px; width: 100%;
    max-width: 40vw;
    margin-bottom: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    &.value-1 {
      background-image: url('../../assets/value-1.jpeg');
      margin-right: 35px;
    }

    &.value-2 {
      background-image: url('../../assets/value-2.jpeg');
      margin-left: 35px;
    }
  }

  .Landing--connect-container {
    margin-top: 240px;
    margin-bottom: 240px;
  }

  .Landing--ddg {
    align-items: center;
  }

  .Landing--ddg-title {
    width: 520px;
    height: auto;
    flex-direction: column;
    margin-bottom: 60px;
    color: var(--black-text);
  }

  .Landing--ddg-intro-section {
    width: auto; height: auto;
    max-width: 1010px;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  .Landing--ddg-picture-container {
    /* width: 22vw; height: 27vh; */
    width: 30vw; height: 218px;
    max-width: 326px;
    background-color: var(--grey4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3.43px;
    padding: 0.86px 0;
    position: relative;
  }
  
  .Landing--ddg-picture-wrapper {
    /* width: 336px; height: 100%; */
    width: 26vw;
    max-width: 282.8px; height: 100%;
    background-color: white;
    position: relative;
    overflow: hidden;
  }

  .Landing--ddg-picture {
    width: 113%; height: auto;
    position: absolute;
    bottom: 0px;
    left: -2px;
  }

  .Landing--ddg-name {
    width: auto; height: 29.1px;
    padding: 0 4px;
    background-color: var(--grey4);
    position: absolute;
    bottom: 0; left: 0;
    border-bottom-left-radius: 3.43px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .Landing--ddg-name-font {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #95EF5D;
  }

  .Landing--ddg-role {
    font-family: 'Recoleta', serif !important;
    font-size: 20px;
    line-height: 26px;
    color: var(--black-text);
    position: absolute;
    top: 4px; left: 4px;
  }
}