@import './../../styles/colors.css';

@media (min-width: 0px) {
  .PopUp--container {
    width: 327px; height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border: none;
    border-radius: 20px;
    box-shadow: 3px 8px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 24px 0 20px 0;
    background-color: white;
  }

  .PopUp--text-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 0;
  }

  .PopUp--title {
    font-size: 20px;
    font-weight: bold;
    line-height: 120%;
    text-align: center;
    color: #181A1B;
  }

  .PopUp--disclaimer {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    color: var(--grey3);
  }

  .PopUp--button-container {
    display: flex;
    gap: 8px;
  }

  .PopUp--button {
    width: 139.5px; height: 52px;
    border: 1px solid black;
    border-radius: 30px;
    background-color: white;
    color: var(--black-text);

    &.black {
      background-color: black;
      color: white;
    }
  }

  .Toast--container {
    position: absolute;
    top: 50%;
    width: 343px; height: 57px;
    border: none;
    border-radius: 12px;
    background-color: var(--grey4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .Toast--container-invitation {
    position: absolute;
    top: 50%;
    width: 343px; height: 57px;
    border: none;
    border-radius: 12px;
    background-color: var(--grey4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .Toast--content {
    font-size: 14px;
    line-height: 120%;
    font-weight: 600;
    color: white;
  }
}

@media (min-width: 768px) {
  .Toast--container {
    position: absolute;
    top: 40px;
    width: 185px; height: 57px;
    max-width: 205px;
  }

  .Toast--container-invitation{
    position: absolute;
    top: -120px;
    width: 185px; height: 57px;
    max-width: 205px;
  }

  .Toast--content {
    font-size: 16px;
    font-weight: 500;
  }
}