@import './../../../styles/colors.css';

@media (min-width: 0px) {
  input::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: var(--grey2);
  }

  .Profile--container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 120px; */
    /* overflow: scroll; */
    gap: 32px;
  }

  .Profile--container-editObjetPC {
    width: 100vw; height: 100vh;
    background-color: transparent;
    display: flex;
    justify-content: center;
  }

  .Profile--container-editObjetPC-title {
    display: none;
  }

  .Profile--container-editObjet{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .Profile--header {
    width: 100vw; height: auto;
    padding: 12px 20px; 
    background-color: black;
    color: white;
  }

  .Profile--header-back-button {
    width: 36px; height: 36px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 10px;
    margin-right: 12px;
  }

  .Profile--content-container {
    width: 342px; height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin-top: 94px; */

    &.huge-gap {
      gap: 44px;
    }
  }

  .Profile--content-container-SignUp {
    width: 342px; height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin-top: 94px; */

    &.huge-gap {
      gap: 44px;
    }
  }

  .Profile--content-container-title {
    display: none;
  }

  .Profile--content-section{
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;

    &.narrow-gap {
      gap: 8px;
    }

    &.wide-gap {
      gap: 12px;
    }

    &.huge-gap {
      gap: 44px;
    }

    &.row {
      flex-direction: row;
    }
  }

  .alumni-question-override{
    margin-top: 88px;
  }

  .student-question-override{
    margin-top: 88px;
  }

  .Profile--content-section-bottomsheet {
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;

    &.narrow-gap {
      gap: 8px;
    }

    &.wide-gap {
      gap: 12px;
    }

    &.huge-gap {
      gap: 20px;
    }

    &.row {
      flex-direction: row;
    }
  }

  .Profile--text-input-box {
    width: 100%; height: 42px;
    border: none;
    border-bottom: 1px solid var(--grey1);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    &.center {
      text-align: center;
    }
  }

  .Profile--text-input-box:focus {
    outline: none;
    border-bottom: 1px solid black;
  }

  .Profile--button-container {
    width: 100%; height: auto;
    display: flex;
    gap: 8px;
    
    &.wide-gap {
      gap: 13px;
    }

    &.fields {
      flex-wrap: wrap;
    }

    &.navigate {
      margin: 12px 0;
    }
  }

  .Profile--button {
    width: 100%; height: 40px;
    border: 1px solid var(--grey2);
    background-color: var(--bg-grey);
    border-radius: 30px;
    color: var(--grey3);

    &.field {
      width: calc((100% - 16px) / 3);
    }

    &.selected {
      background-color: var(--grey4);
      border: 1px solid var(--grey4);
      color: var(--bg-grey);
    }
  }

  .Profile--button-gender {
    width: 100%; height: 40px;
    border: 1px solid var(--grey2);
    background-color: var(--bg-grey);
    border-radius: 30px;
    color: var(--grey3);

    &.field {
      width: calc((100% - 16px) / 3);
    }

    &.selected {
      background-color: var(--grey4);
      border: 1px solid var(--grey4);
      color: var(--bg-grey);
    }
  }

  .Profile--warning-msg {
    color: red;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .Profile--dropdown-menu {
    width: 100%; height: 40px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    background-color: var(--bg-grey);

    /* arrow */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../assets/profile-select-arrow.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: calc(100% - 12px);

    &.placeholder {
      color: #A8B3BD;
    }
  }

  .Profile--dropdown-menu:focus {
    border: 1px solid black;
    outline: none;
  }

  .Profile--dropdown-menu:disabled {
    opacity: 0.3;
  }

  /* Alumni */
  .Profile--navigate-button {
    width: 100%; height: 52px;
    max-width: 342px;
    margin-top: 258px;
    border: 1px solid black;
    background-color: black;
    border-radius: 30px;
    color: white;

    &.prev {
      background-color: white;
      color: var(--black-text);
      border: 1px solid black;
    }
  }

  /* Parents */
  .Profile--navigate-button-parents {
    width: 100%; height: 52px;
    max-width: 342px;
    margin-top: 80px;
    border: 1px solid black;
    background-color: black;
    border-radius: 30px;
    color: white;

    &.prev {
      background-color: white;
      color: var(--black-text);
      border: 1px solid black;
    }
  }

  /* Students */
  .Profile--navigate-button-students{
    width: 100%; height: 52px;
    max-width: 342px;
    margin-top: 233px;
    border: 1px solid black;
    background-color: black;
    border-radius: 30px;
    color: white;

    &.prev {
      background-color: white;
      color: var(--black-text);
      border: 1px solid black;
    }
  }

  .Profile--navigate-button-email{
    font-size: 14px;
  }

  .basic-info-next-btn{
    margin-top: 0px;
  }

  .basic-info-education-btn{
    margin-top: 0px;
  }

  .basic-info-career-btn{
    margin-top: 0px;
  }


  .Profile--navigate-button:disabled {
    border: none;
    cursor: not-allowed;
    opacity: 0.45;
    color: rgba(256, 256, 256, 0.45);
  }

  .Profile--navigate-button-parents:disabled {
    border: none;
    cursor: not-allowed;
    opacity: 0.45;
    color: rgba(256, 256, 256, 0.45);
  }

  .Profile--add-education {
    width: 100%; height: 64px;
    border: 1px dashed var(--grey2);
    border-radius: 4px;
    background-color: var(--bg-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: var(--grey2);
  }

  .Profile--add-education-icon {
    width: 23px; height: 23px;
    background-image: url('../../../assets/profile-add-edu.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .Profile--checkbox-input input[type="checkbox"] {
    display: none;
  }

  .Profile--checkbox-input {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    margin-top: 16px;

    &::before {
      content: "";
      background-color: white;
    }

    &:checked {
      background-color: blue;
    }
  }

  .Profile--checkbox-input .checkmark {
    position: relative;
    width: 22px; height: 22px;
    border: 1px solid var(--grey2);
    opacity: 0.4;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .Profile--checkbox-input input:checked ~ .checkmark {
    background-color: #3479FF;
    border: 1px solid #3479FF;
    opacity: 1;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .Profile--checkbox-input .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .Profile--checkbox-input input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .Profile--checkbox-input .checkmark:after {
    width: 100%; height: 100%;
    background-image: url('../../../assets/profile-check.png');
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
  }

  .label-text {
    color: var(--black-text);
    opacity: 0.4;
  }

  .Profile--checkbox-input input:checked ~ .label-text {
    color: var(--black-text);
    opacity: 1;
  }

  .Profile--header-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  /* .Profile--education-history-container {
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: var(--black-text);
  } */

  .SignUp-PC-title {
    display: none;
  }

  .Education--buttons-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .Career--buttons-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .button-to-hide{
    display: none;
  }
  
  .text-PC-18-to-20 {
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 600;
  }
}

@media (min-width: 768px){
  input::placeholder {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: var(--grey2);
  }

  .Profile--header {
    display: none;
  }

  .Profile--container {
    margin-top: 160px;
    gap: 263px;
  }

  .parents-container-override{
    margin-top: -80px;
  }
  
  .Profile--container-gray {
    /* position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px; */
    width: 100vw;
    margin-top: 0px;
    padding-top: 112px; /* 41 + 71 px*/
    min-height: 100vh;
    background-color: #fafafa;
  }

  .Profile--container-editObjetPC {
    background-color: rgba(43, 55, 68, 1);
  }

  .Profile--container-editObjetPC-title {
    display: block;
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 5px;
  }

  .Profile--container-editObjet{
    width: 597px; height: 612px;
    border-radius: 30px;
    background-color: white;
    margin-top: 60px;
    padding-top: 0px;
    padding-bottom: 20px;
  }
  
  .Profile--content-container {
    width: 400px;
    max-width: 640px;
    /* margin-top: 113px;
    padding-top: 40px;
    padding-bottom: 40px; */
    /* background-color: white; */
    border-radius: 30px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;      /* Centers vertically */
  }

  .Profile--content-container-SignUp{
    width: 400px;
    padding: 40px 120px 40px 120px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;      /* Centers vertically */
    margin-bottom: 110px;

    &.huge-gap {
      gap: 60px;
    }
  }

  .Profile--dropdown-menu {
    font-size: 18px;
    &.placeholder {
      font-size: 18px;
    }
  }

  .alumni-question-override{
    margin-top: 0px;
  }

  .student-question-override{
    margin-top: 0px;
  }

  .Profile--content-section-bottomsheet {
    width: 200px;
  }

  .Profile--text-input-box {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .Profile--navigate-button{
    max-width: 400px;
    /* width: 400px; */
    margin-top: 0px;
  }

  .basic-info-next-btn{
    max-width: 400px;
  }

  .Profile--navigate-button-parents {
    max-width: 400px;
    width: 400px;
    margin-top: -108px;
  }

  .Profile--navigate-button-students {
    max-width: 400px;
    width: 400px;
    margin-top: 0px;
  }

  .Profile--navigate-button-email{
    font-size: 18px;
  }

  .Profile--button {
    /* width: 200px; height: 40px; */
    width: 14vw; height: 40px;
  }

  .Profile--button-gender {
    width: 191.25px; height: 40px;
  }

  /* .Profile--button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--black-text);
  } */

  .Archive--side-filter{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--black-text);
  }

  .SignUp-PC-title {
    display: block;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: -4px;
  }

  .Education-PC-title{
    margin-bottom: -20px;
  }

  .text-PC-18-to-20 {
    font-size: 20px;
  }

  .Education--wrapper{
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #68717a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Education--container{
    display: flex;
    width: 400px;
    max-width: 400px;
    border-radius: 30px;
    background-color: white;
    padding: 40px 98px 0px 99px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    gap: 60px;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
  }

  .Education--buttons-wrapper-gray{
    width: 100%;
    height: 92px;
    padding: 0px 98px 0px 99px;
    margin-top: -12px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #D3DEE6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Education--buttons-div{
    width: 400px;
  }

  .Career--buttons-div{
    width: 400px;
  }

  .button-to-hide{
    display: block;
    width: 100%; height: 52px;
    border: 1.5px solid black;
    color: var(--black-text);
    border-radius: 30px;
    background-color: white;
  }
}