:root{
  --primary-red: #FE3C2A;
  --secondary-yellow: #F6E5AC;
  --black: #000000;
  --white: #ffffff;
  --bg-grey: #fafafa;
  --grey1: #D3DEE6;
  --grey2: #A8B3BD;
  --grey3: #66707A;
  --grey4: #2B3744;
  --black-text: #2F2F2F;
}