@import './../../../styles/colors.css';

@media (min-width: 0px) {
  .EditProfile--title-temporary{
    display: none;
  }

  .EditProfileInfo--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
  }

  .EditProfileInfo--container {
    width: auto; height: 100%;
    max-width: 390px;
    padding: 32px 24px 0 24px;
    display: flex;
    flex-direction: column;
  }
  
  .EditProfileInfo--picture {
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: var(--grey3);
    margin-bottom: 44px;
  }
  
  .EditProfileInfo--basic-info {
    width: auto; height: auto;
    padding: 20px 35px 20px 20px;
    display: flex;
    border: 1px solid var(--grey2);
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .EditProfileInfo--basic-info-category {
    width: auto; height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #66707A;
  }

  .EditProfileInfo--basic-info-content {
    width: auto; height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .EditProfileInfo--additional-info {
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
  }

  .EditProfileInfo--delete-account {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: var(--grey3);
    background-color: white;
    border: none;
    text-decoration: underline;
    margin-top: 132px;
  }

}

@media (min-width: 768px) {
  .EditProfile--title-temporary{
    display: block;
    text-align: center;
  }

  .b8-32-p {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .EditProfileInfo--wrapper {
    padding-bottom: 0px;
  }

  .EditProfileInfo--container {
    max-width: 400px;
    padding: 0 0 0 0;
  }

  .EditProfileInfo--delete-account {
    margin-top: 160px;
    margin-bottom: 132px;
  }
}