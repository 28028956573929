@import './../../styles/colors.css';

@media (min-width: 0px) {
  .FilterOption--container {
    width: auto; height: auto;
    border: 0.5px solid var(--grey1);
    border-radius: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 6px 10px;
  }
/*   
  .FilterOption--img {
    width: auto; height: auto;
    padding: 4px;
  } */
  
  .FilterOption--text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--grey3);
    display: flex;
    gap: 4px;
  }

  .MobileFilterContent--button-container {
    width: 100%; height: auto;
    display: flex;
    gap: 8px;
    padding: 12px 0;
  }

  .MobileFilterContent--button-reset {
    width: 52px; height: 52px;
    border: 1.5px solid black;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.disable {
      opacity: 0.3;
      border: 1.5px solid rgba(0, 0, 0, 0.7);
    }
  }

  .MobileFilterContent--button {
    width: 282px; height: 52px;
    border: none;
    border-radius: 30px;
    background-color: black;
    color: white;

    &.disable {
      opacity: 0.4;
      color: rgba(256, 256, 256, 0.6);
    }
  }
}

@media (min-width: 768px) {
  .MobileFilterContent--button-container {
    padding: 0px;
    position: relative;
    /* left: 2.5vw; */
  }

  .MobileFilterContent--button {
    width: 140px;
    /* width: calc(14vw - 60px); */
  }

  .PCFilter--container {
    display: flex;
    flex-direction: column;
    width: 280px;
    align-items: center;
    border: none;
    border-right: 1.5px solid var(--grey1);
    margin-right: 75px;
    background-color: white;
    padding-top: 53px;
  }

  .PCFilter--header {
    width: 200px; height: auto;
    display: flex;
    justify-content: space-between;
  }

  .PCFilter--close {
    height: 24px; width: 24px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    line-height: 24px;
  }

  .PCFilter--title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  .PCFilter--content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 200px; height: auto;
    margin-top: 53px;
  }

  .PCFilter--subtitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--black-text);
  }

  .FilterButton--container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .FilterButton--box {
    width: 100%;
    height: 30px;
    border: 1px solid var(--grey2);
    background-color: var(--bg-grey);
    border-radius: 30px;
    color: var(--grey3);

    &.selected {
      background-color: var(--grey4);
      border: 1px solid var(--grey4);
      color: var(--bg-grey);
    }
  }

}