@import './../../styles/colors.css';

@media (min-width: 0px) {
  .ProfilePage--container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100vw; height: 100vh;
    padding-top: 137px;
  }

  .ProfilePage--button-container {
    width: 342px; height: auto;
    padding: 12px 0;
    display: flex;
    gap: 6px;
  }

  .ProfilePage--button{
    width: 100%; height: 52px;
    border: 1.5px solid black;
    color: var(--black-text);
    border-radius: 30px;
    background-color: white;

    &.black {
      background-color: black;
      color: white;
    }
  }

  .ProfilePage--button:disabled {
    border: none;
    cursor: not-allowed;
    opacity: 0.45;
    color: rgba(256, 256, 256, 0.45);
  }

  .ProfilePage--logout {
    color: var(--grey3);
    text-decoration: underline;
    margin-top: 120px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
  }

  .MyInfoCard--container {
    position: relative;
    height: 222px;
    width: 260px;
    border: none;
    background-color: white;
    box-shadow: 0px 1px 34px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    gap: 34.79px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .MyInfoCard--header-container {
    display: flex;
    gap: 25.82px;
  }

  .MyInfoCard--header-text {
    width: 99.19px; height: auto;
  }

  .MyInfoCard--header-img-container {
    height: 96px; width: 96.18px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .MyInfoCard--header-img {
    object-fit: cover;
    width: 100%; height: 100%;
    display: block;
    margin: auto;
  }

  .MyInfoCard--content-container {
    width: 223.93px; height: auto;
    display: flex;
    flex-direction: column;
    gap: 4.51px;
  }

  .objet-wrapper {
    width: 308px; height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 53px;
    margin-bottom: 20px;
  }

  .objet-container {
    width: 68px; height: 68px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .objet {
    display: block;
    margin: auto;
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }

  .objet-preview-container {
    width: 180px; height: 180px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }

  .MyInfoCard--content-intro {
    white-space: nowrap; /* 텍스트를 한 줄로 표시 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */
  }
}

@media (min-width: 768px) {
  .MyInfoCard--container {
    width: 319px;
    height: 262px;
    /* width: 50vw; height: 262px; */
    gap: 30px;
  }

  .MyInfoCard--header-img-container {
    height: 142px; width: 142px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .MyInfoCard--header-container {
    display: flex;
    gap: 19px;
  }

  .MyInfoCard--header-text {
    width: 119px; height: auto;
    max-width: 119px;
  }

  .MyInfoCard--content-container {
    width: 280px;
    gap: 11px;
  }

  .MyInfoCard--content-intro {
    white-space: nowrap; /* 텍스트를 한 줄로 표시 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */
    /* font-size: 16px; */
  }

  .ProfilePage--button-container {
    width: 400px; height: auto;
    padding: 12px 0;
    display: flex;
    gap: 8px;
  }

  .ProfilePage--button{
    width: 100%; height: 52px;
    border: 1.5px solid black;
    color: var(--black-text);
    border-radius: 30px;
    background-color: white;

    &.black {
      background-color: black;
      color: white;
    }
  }

  .ProfilePage--button-container-editObjet {
    width: 100%; height: auto;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 0px 0px 30px 30px;
    border-top: 1px solid #D3DEE6;
  }

  .ProfilePage--logout {
    margin-top: 200px;
    margin-bottom: 160px;
  }

  .objet-wrapper {
    width: 480px; height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 60px;
    margin-bottom: 48px;
  }

  .pc-font-20{
    font-size: 20px;
  }
}

/* This is test push sentence to PC_frontend branch */