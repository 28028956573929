@import '../../styles/colors.css';

@media (min-width: 0px) {
  .Modal--container {
    width: 258px; height: auto;
    max-height: 674px;
    background-color: white;
    border: none;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .Modal--header {
    width: 100%; height: auto;
    background-color: var(--secondary-yellow);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Modal--profile-header-content {
    display: flex;
    text-align: center;
    width: auto; height: auto;
    gap: 20px;
    margin-bottom: 14px;
  }

  .Modal--profile-header-link {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-text);
    text-decoration: underline;
  }

  .Modal--profile-header-link-button {
    width: 12px; height: 12px;
  }

  .Modal--close-button-container {
    width: 100%; height: auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
  }
  
  .Modal--close-button {
    background-color: transparent;
    border: none;
    width: 30px; height: 30px;
    background-image: url('../../assets/modal-close.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin: 12px 12px 0 0;
  }

  .Modal--profile-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    height: 98px;
    overflow: hidden;
    margin-bottom: 18px;
    border-radius: 50%;
    border: 1px solid black;
  }

  .Modal--profile-picture {
    object-fit: cover;
    width: 100%; height: 100%;
    display: block;
    margin: auto;
  }

  .Modal--content-container {
    width: auto; height: auto;
    /* min-height: 201px; */
    max-height: 467px;
    background-color: white;
    border: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 32px 24px 48px 24px;
    display: flex;
    flex-direction: column;
    gap: 21.5px;
  }

  .Modal--basic-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: var(--black-text);

    &.narrow-gap {
      gap: 6px;
    }

    &.row {
      flex-direction: row;
    }

    &.grey-text {
      color: var(--grey3);
    }

    &.center {
      align-items: center;
    }
    
    &.margin {
      margin-top: 12px;
    }
  }

  .Modal--basic-info-icon {
    height: auto; width: auto;
  }

  .Modal--divider {
    width: 100%; height: 1px;
    background-color: var(--grey1);
  }

  .Modal--additional-info {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: auto; height: auto;
    max-height: 210px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--grey1) transparent;
  }

  .Modal--addtional-info-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
    color: var(--black-text);
  }

  .Modal--additional-info-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-text);
  }
  
  .Modal--additional-info-content {
    width: auto; height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .Modal--education-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

@media (min-width: 768px) {
  .Modal--container {
    z-index: 1;
    width: 750px; height: auto;
    max-height: 447px;
    flex-direction: row;
  }

  .Modal--header {
    z-index: inherit;
    width: 39.46%; height: inherit;
    background-color: var(--secondary-yellow);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Modal--close-button-container {
    margin-bottom: 1px;
  }
  
  .Modal--close-button {
    width: 41px; height: 41px;
    margin: 0 0 0 0;
  }

  .Modal--profile-picture-container {
    margin: 58px 58px 24px 58px;
    width: 180px;
    height: 180px;
  }

  .Modal--profile-header-link {
    font-size: 16px;
  }

  .Modal--profile-header-content {
    margin-bottom: 58px;
  }

  .Modal--profile-header-link-button {
    width: 14px; height: 14px;
  }

  .Modal--content-container {
    width: inherit;
    padding: 16px 16px 48px 48px;
    /* padding: 32px 24px 48px 24px; */
    border-top-right-radius: 16px;
    gap: 0px;
  }

  .Modal--divider {
    margin-top: 20px;
    margin-bottom: 44px;
  }
}