.NavBar--container-temporary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 70px;
  width: 100vw;
  border-bottom: 1px solid #d6d6d6;
}

.hide {
  display: none;
}

@media (min-width: 0px) {
  .NavBar--container-temporary {
    display: none;
  }

  .SignUpSuccess--wrapper {
    width: 100vw; height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 390px;
    flex-direction: column;
  }

  .SignUpSuccess--background {
    width: 100%; height: 310px;
    background-image: url('./../../../../assets/membership-background-yellow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .SignUpSuccess--container {
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;
    gap: 41.33px;
  }

  .SignUpSuccess--button-container {
    width: 342px; height: auto;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    margin-top: 27.67px;
  }

  .SignUpSuccess--button {
    width: 100%; height: 52px;
    border: none;
    background-color: black;
    color: white;
    border-radius: 30px;
    padding: 0;

    &.white {
      background-color: white;
      color: black;
      border: 1.5px solid black;
    }
  }

  .fade-in {
    opacity: 0; /* 초기 투명도 설정 */
    animation: fadeIn 3s forwards; /* 애니메이션 설정 */
    margin-top: 77px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0; /* 시작 시 투명도 */
    }
    to {
      opacity: 1; /* 끝날 때 투명도 */
    }
  }

  .SuccessCard--container {
    position: relative;
    height: 197px;
    width: 226px;
    border: none;
    background-color: white;
    box-shadow: 0px 1px 34px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .SuccessCard--header-container {
    display: flex;
    /* gap: 20.96px; */
    width: 194px;
    justify-content: space-between;
  }

  .SuccessCard--header-text {
    width: auto; height: auto;
    max-width: 90px;
    display: flex;
    flex-wrap: wrap;
  }

  .SuccessCard--header-img-container {
    height: 96px; width: 96px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .SuccessCard--header-img {
    object-fit: cover;
    width: 100%; height: 100%;
    display: block;
    margin: auto;
  }

  .SuccessCard--content-container {
    width: 194px; height: auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .SuccessCard--content-intro {
    white-space: nowrap; /* 텍스트를 한 줄로 표시 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */
  }
}

@media (min-width: 768px) {

  .NavBar--container-temporary{
    display: block;
    z-index: 5;
    position: absolute;
    top: 0px;
  }

  .SignUpSuccess--background {
    width: 100%; height: 546px;
    background-size: 586.5px 546px;
    /* margin-bottom: 50px; */
  }

  .SignUpSuccess--container-text-celeb {
    display: none;
  }

  .SignUpSuccess--container-text-celeb-student{
    /* display: none; */
    position: absolute;
    top: 300px;
  }

  .SignUpSuccess--button-container {
    width: 400px;
    gap: 12px;
  }

  .SignUpSuccess--button {
    margin-top: -194px;
  }

  .SuccessCard--container{
    width: 320px;
    height: 262px;
    /* margin-bottom: -500px; */
    margin-top: 80px;
    gap: 30px;
  }

  .SuccessCard--header-container {
    width: 280px;
  }

  .SuccessCard--header-text {
    width: 130px;
  }

  .SuccessCard--content-container {
    width: 280px;
  }

  .SuccessCard--header-img-container {
    height: 142px; width: 142px;
  }
}