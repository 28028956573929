@import '../../styles/colors.css';

@media (min-width: 0px) {
  .PictureUploader--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PictureUploader--preview {
    width: 108px; height: 108px;
    border: 1px solid var(--grey2);
    border-radius: 50%;
    background-color: var(--bg-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
  }

  .PictureUploader--upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .PictureUploader--upload-label-picture {
    width: 100%; height: 100%;
  }
}

@media (min-width: 768px) {
  .PictureUploader--preview {
    width: 118px; height: 118px;
  }
}