@import '../../styles/colors.css';

@media (min-width: 0px) {
  .Archive--content-wrapper {
    display: flex;
    width: 100vw;
    height: 100%;
  }
  
  .Archive--filter-animation {
    position: relative; 
    height: 100%;
    width: auto;
  
    &.slideIn {
      animation: slideIn 0.3s ease;
    }
    &.slideOut {
      animation: slideOut 0.3s ease;
    }
  }
  
  @keyframes slideIn {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
  }
  
  @keyframes slideOut {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
  }
  
  .Archive--content-container {
    width: 100%;
    height: auto;
    margin: 37px 48px 0px 48px;
  
    &.active {
      width: 1118px;
      margin-left: 68px;
      transition: all 0.3s ease;
    }
  }
  
  .Archive--header {
    display: flex;
    max-width: 1344px; 
    width: 100%;
    width: auto;
    height: auto;
    margin-bottom: 80px;
  
    &.filtered {
      margin-bottom: 16px;
    }
  }
  
  .Archive--header-filter {
    padding: 4px;
  }
  
  .Archive--header-filter-container {
    display: flex;
    align-items: center;
    width: 60px; height: 24px;
    justify-content: space-between;
  }
  
  .Archive--filter-options {
    width: 100%; height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  
  .Archive--filter-options-wrapper {
    width: auto; height: auto;
    display: flex;
    gap: 4px;
  }
  
  .Archive--filter-reset {
    background-color: transparent;
    border: none;
    width: 130px; height: 15px;
  }
  
  .Archive--filter-reset-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 110%;
  }
  
  .Archive--results-number {
    width: 100%; height: auto;
    margin-bottom: 8px;
  }
  
  .Archive--cards {
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 40px;
  }
  
  .Archive--modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 55, 68, 0.7);
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Mobile styling */
  
  .Archive--wrapper {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background-color: var(--bg-grey);
    width: 100%;
    height: 100vh;
    /* padding-bottom: 20px; */
    &.selected {
      justify-content: normal;
    }
  }
  
  .Archive--container {
    width: 100%; height: auto;
    max-width: 358px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .Archive--header-container {
    width: 100%; height: auto;
    display: flex;
    gap: 12px;
    margin-top: 20px;
  }
  
  .Archive--filter-button {
    width: 80px; height: 45px;
    min-width: 80px;
    border: 1px solid var(--grey1);
    border-radius: 30px;
    background-color: var(--bg-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    color: black;

    &.active {
      background-color: black;
      border: 1px solid black;
      color: white;
    }
  }

  .Archive--filter-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .Archive--mid-section-container {
    width: 100%; height: auto;
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
  
  .Archive--filter-options-wrapper {
    display: flex;
    gap: 11px;
  }
  
  .Archive--filter-options-container {
    width: auto; height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .Archive--cards-container {
    width: 100%; height: auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 12px;
    align-items: center;
    padding-bottom: 30px;
  }

  .Archive--popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Search Page */
  .Search--background {
    width: 100vw; height: 100vh;
    background-color: var(--bg-grey);
    z-index: 100;
    touch-action: none; /* 터치 이벤트를 막음 */
    pointer-events: auto; /* 포인터 이벤트를 활성화 */
    position: absolute;
    top: 0;
  }

  .Search--container {
    width: auto; height: auto;
    display: flex;
    gap: 10px;
    margin: 18px 16px 0 16px;
  }

  .Search--button {
    width: 36px; height: 36px;
    background-image: url('./../../assets/search-back-button.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .NoResult--container {
    width: 100%; height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 30px;
    text-align: center;
    margin-top: 92px;
  }

  .NoResult--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--grey3);
  }
}

@media (min-width: 768px) {
  .Archive--cards-disclaimer {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    margin-top: 242px;
    margin-bottom: 153px;
  }

  .Archive--filter-button {
    width: 128px; height: 48px;
    min-width: 128px;
    display: flex;
    gap: 16px;
  }

  .Archive--filter-text {
    font-size: 16px;
    line-height: 24px;
  }

  .Archive--header-container {
    max-width: 95vw;
    width: 1008px; height: auto;
    display: flex;
    gap: 20px;
    margin-top: 40px;
  }

  .Archive--container {
    width: 100%; height: auto;
    max-width: 1008px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .Archive--filter-options-container {
    width: 100%; height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .NoResult--container {
    width: 100%; height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 30px;
    text-align: center;
    margin-top: 92px;
  }

  .Archive--wrapper {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: auto;
    justify-content: center;
    background-color: var(--bg-grey);
    &.selected {
      justify-content: normal;
    }
  }

  .Archive--cards-container {
    justify-content: left;
    row-gap: 24px;
    column-gap: 22px;
  }

  .pc-font-14{
    font-size: 14px;
  }

  /* .pc-padding-top-28{
    padding-top: 28px;
  } */

  .Archive--mid-section-container {
    max-width: 95vw;
    width: 1008px; height: auto;
    gap: 20px;
  }

  .noresult_title {
    font-size: 16px;
  }

  .noresult_subtitle {
    font-size: 14px;
  }
}

.pc-filter-container {
  /* initial hidden state */
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.3s ease;
}

.pc-filter-container.active {
  max-width: 280px; /* or your desired width */
}